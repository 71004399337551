import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import UserRating from 'components/rating/user-rating';
import UserInfoColumn from 'components/tables/user-info-column';
import { GroupInvitesListItem, GroupInviteStatus } from 'types/group-invites';
import { RatingLevel } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';
import './GroupInvitesTable.scss';

type Props = {
	dataSource: GroupInvitesListItem[];
};

const getStatusCn = (status: GroupInviteStatus): string => {
	switch (status) {
		case GroupInviteStatus.ACCEPTED: return 'group-invites-table__status_accepted';
		case GroupInviteStatus.REJECTED: return 'group-invites-table__status_rejected';
		case GroupInviteStatus.SENT: return 'group-invites-table__status_sent';
		default: return '';
	}
};

const GroupInvitesTable = ({ dataSource }: Props): JSX.Element => {
	const columns: ColumnsType<GroupInvitesListItem> = [
		{
			title: () => (
				<FormattedMessage defaultMessage="User name" id={Dictionary.groupInvitesPage.table.userName} />
			),
			dataIndex: 'nickname',
			key: 'nickname',
			render: (_: string, record: GroupInvitesListItem) => (
				<UserInfoColumn showRatingPosition={false} userInfo={record} />
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Rating" id={Dictionary.groupInvitesPage.table.rating} />,
			dataIndex: 'levelTitle',
			key: 'levelTitle',
			render: (levelTitle: RatingLevel, record: GroupInvitesListItem) => (
				<UserRating
					intStars={record.intStars}
					levelTitle={levelTitle}
					starThirds={record.starThirds}
				/>
			),
		},
		{
			title: () => (
				<FormattedMessage defaultMessage="Invite Status" id={Dictionary.groupInvitesPage.table.inviteStatus} />
			),
			dataIndex: 'status',
			key: 'status',
			render: (status: GroupInviteStatus) => (
				<span className={getStatusCn(status)}>
					{status}
				</span>
			),
		},
	];

	return (
		<Table
			className="group-invites-table"
			columns={columns}
			dataSource={dataSource}
			rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default GroupInvitesTable;
