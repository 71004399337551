import { Link } from 'react-router-dom';
import Avatar from 'components/avatar';
import ButtonEdit from 'components/forms/button-edit';
import ButtonEditRound from 'components/forms/button-edit-round';
import ButtonReport from 'components/forms/button-report';
import ButtonReportRound from 'components/forms/button-report-round';
import UserRating from 'components/rating/user-rating';
import ReportModal from 'components/report-modal';
import { getUserId } from 'store/auth';
import { getGlossaryData } from 'store/glossary';
import { AppTheme, IdType, ImageContainer } from 'types/common';
import { Country, GlossaryEnum } from 'types/glossary';
import { UserInfo } from 'types/user';
import { RatingStarsMode } from 'utils/constants/rating';
import getUserLocation from 'utils/helpers/get-user-location';
import { useAppSelector, useWindow } from 'utils/hooks';
import './UserInfoPanel.scss';

type Props<T > = {
	className?: string;
	editable?: boolean;
	linkUserAvatar?: boolean;
	profile: T;
	ratingStarsMode?: RatingStarsMode;
	showGroupAvatar?: boolean;
	onClickEdit?: () => void;
};

type GroupData = {
	groupAvatarImageId?: IdType | null;
	groupId?: IdType | null;
};

const UserInfoPanel = <T extends UserInfo & GroupData>({
	className, editable = false, linkUserAvatar = false, profile,
	ratingStarsMode = RatingStarsMode.BLACK, showGroupAvatar = true, onClickEdit,
}: Props<T>): JSX.Element => {
	const [reportModal, showReportModal, hideReportModal] = useWindow();

	const countries: Country[] | null = useAppSelector(getGlossaryData<Country[] | null>(GlossaryEnum.COUNTRIES));
	const userId: IdType | null = useAppSelector(getUserId);

	const isCurrentUser: boolean = profile.id === userId;

	return (
		<div className={`user-info-panel ${className || ''}`}>
			<div className="user-info-panel__left">
				<div className="user-info-panel__avatars">
					{	linkUserAvatar ? (
						<Link to={`/profile/${profile.id}`}>
							<Avatar
								alt="user-profile-avatar"
								className="user-info-panel__profile-avatar"
								containerName={ImageContainer.USER_AVATAR}
								imageId={profile.avatarImageId}
							/>
						</Link>
					) : (
						<Avatar
							alt="user-profile-avatar"
							className="user-info-panel__profile-avatar"
							containerName={ImageContainer.USER_AVATAR}
							imageId={profile.avatarImageId}
						/>
					)}

					{showGroupAvatar && profile.groupId && (
						<Link to={`/group${isCurrentUser ? '' : `/${profile.groupId}`}`}>
							<Avatar
								alt="user-group-avatar"
								className="user-info-panel__group-avatar"
								containerName={ImageContainer.GROUP_AVATAR}
								imageId={profile.groupAvatarImageId || null}
							/>
						</Link>
					)}
				</div>

				<div className="user-info-panel__user-data">
					<h2>
						{profile.nickname}
					</h2>
					<h3>
						{getUserLocation(profile, countries || [])}
					</h3>
				</div>
			</div>

			<div className="user-info-panel__right">
				<div className="user-rating-right">
					<UserRating
						intStars={profile.intStars}
						levelTitle={profile.levelTitle}
						ratingStarsMode={ratingStarsMode}
						starThirds={profile.starThirds}
						theme={AppTheme.DARK}
					/>
				</div>
			</div>

			{/* lg-xl */}
			{editable && onClickEdit && <ButtonEdit onClick={onClickEdit} />}

			{!editable && profile.avatarImageId && !isCurrentUser && (
				<ButtonReport onClick={showReportModal} />
			)}

			{/* xs-md */}
			{editable && onClickEdit && <ButtonEditRound onClick={onClickEdit} />}

			{!editable && profile.avatarImageId && !isCurrentUser && (
				<ButtonReportRound onClick={showReportModal} />
			)}

			{reportModal && profile.avatarImageId && (
				<ReportModal onClose={hideReportModal} onFulfilled={hideReportModal} userId={profile.id} />
			)}
		</div>
	);
};

export default UserInfoPanel;
