import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import UserRating from 'components/rating/user-rating';
import UserInfoColumn from 'components/tables/user-info-column';
import { getUserId } from 'store/auth';
import { fetchGroupMembersRankingAsync, getGroupMembersRankingData } from 'store/group-members-ranking';
import { IdType } from 'types/common';
import { GroupMembersRankingItem, RatingLevel } from 'types/rating';
import { UserInfo } from 'types/user';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './GroupMembersRanking.scss';

type Props = {
	groupId: IdType;
};

const GroupMembersRanking = ({ groupId }: Props): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const userId: IdType | null = useAppSelector(getUserId);

	const groupMembersRating: GroupMembersRankingItem[] | null = useAppSelector(getGroupMembersRankingData);

	useEffect(() => {dispatch(fetchGroupMembersRankingAsync(groupId));}, [dispatch, groupId]);

	if (!groupMembersRating || !userId) return null;

	const columns: ColumnsType<GroupMembersRankingItem> = [
		{
			title: () => (
				<div>
					<FormattedMessage defaultMessage="Member name" id={Dictionary.groupPage.table.memberName} />
				</div>
			),
			dataIndex: 'id',
			key: 'id',
			render: (id: IdType, record) => (
				<UserInfoColumn active={id === userId} userInfo={record} />
			),
		},
		{
			title: () => (
				<div>
					<FormattedMessage defaultMessage="Rating" id={Dictionary.groupPage.table.rating} />
				</div>
			),
			dataIndex: 'levelTitle',
			key: 'levelTitle',
			render: (levelTitle: RatingLevel, record) => (
				<UserRating
					intStars={record.intStars}
					levelTitle={levelTitle}
					ratingStarsMode={record.id === userId ? RatingStarsMode.ORANGE : RatingStarsMode.BLACK}
					starThirds={record.starThirds}
				/>
			),
		},
		{
			title: () => (
				<div>
					<FormattedMessage defaultMessage="Score" id={Dictionary.groupPage.table.score} />
				</div>
			),
			dataIndex: 'salesAmount',
			key: 'salesAmount',
			render: (salesAmount: number, record: UserInfo) => (
				<div className={record.id === userId ? 'active' : ''}>
					{salesAmount}
				</div>
			),
		}];

	return (
		<Table
			className="group-members-ranking table"
			columns={columns} dataSource={groupMembersRating} pagination={false} rowKey="id"
		/>
	);
};

export default GroupMembersRanking;
