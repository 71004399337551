import UserRating from 'components/rating/user-rating';
import { UserProfile } from 'types/user-profile';
import { useUserProfile } from 'utils/hooks/use-user-profile';
import UserBalance from 'views/layout/user-balance';
import UserGreeting from 'views/layout/user-greeting';
import { RatingStarsMode } from 'utils/constants/rating';
import './UserInfoMobile.scss';

const UserRatingData = (): JSX.Element | null => {
	const userProfile: UserProfile | null = useUserProfile();

	if (!userProfile) return null;

	return (
		<UserRating
			intStars={userProfile.intStars}
			levelTitle={userProfile.levelTitle}
			ratingStarsMode={RatingStarsMode.WHITE}
			starThirds={userProfile.starThirds}
		/>
	);
};

const UserInfoMobile = (): JSX.Element | null => (
	<div className="card user-info-mobile">
		<div className="user-info-mobile__user-data">
			<UserGreeting />
			<UserBalance />
		</div>

		<UserRatingData />
	</div>
);

export default UserInfoMobile;
