import { FormattedMessage } from 'react-intl';
import { RatingLevel } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';
import './StatusLabel.scss';

const getClassName = (levelTitle: RatingLevel): string => {
	switch (levelTitle) {
		case RatingLevel.GOAL_GETTER_I:
		case RatingLevel.GOAL_GETTER_II: {
			return 'status-label_blue';
		}
		case RatingLevel.LEADER_I:
		case RatingLevel.LEADER_II: {
			return 'status-label_gold';
		}
		case RatingLevel.TRUE_LEADER_I:
		case RatingLevel.TRUE_LEADER_II: {
			return 'status-label_black';
		}

		case RatingLevel.PARTNER: return 'status-label_purple';

		default: return '';
	}
};

const getStatusId = (levelTitle: RatingLevel): string => {
	switch (levelTitle) {
		case RatingLevel.GOAL_GETTER_I: return Dictionary.profileStatus.goalGetter_I;
		case RatingLevel.GOAL_GETTER_II: return Dictionary.profileStatus.goalGetter_II;
		case RatingLevel.LEADER_I: return Dictionary.profileStatus.leader_I;
		case RatingLevel.LEADER_II: return Dictionary.profileStatus.leader_II;
		case RatingLevel.TRUE_LEADER_I: return Dictionary.profileStatus.trueLeader_I;
		case RatingLevel.TRUE_LEADER_II: return Dictionary.profileStatus.trueLeader_II;
		case RatingLevel.PARTNER: return Dictionary.profileStatus.partner;
		default: return 'default-id';
	}
};

const getFormattedMsgProps = (levelTitle: RatingLevel): {
	defaultMessage: string;
	id: string;
} => ({ defaultMessage: levelTitle, id: getStatusId(levelTitle) });

type Props = {
	levelTitle: RatingLevel;
};

const StatusLabel = ({ levelTitle }: Props): JSX.Element => (
	<div className={`status-label ${getClassName(levelTitle)}`}>
		<FormattedMessage {...getFormattedMsgProps(levelTitle)} />
	</div>
);

export default StatusLabel;
