export const Dictionary = {
	auth: {
		confirmNewPassword: 'auth.confirmNewPassword',
		createNewPassword: 'auth.createNewPassword',
		email: 'auth.email',
		emailSentSuccessfully: 'auth.emailSentSuccessfully',
		enterEmail: 'auth.enterEmail',
		forgotPassword: 'auth.forgotPassword',
		login: 'auth.login',
		logout: 'auth.logout',
		newPassword: 'auth.newPassword',
		password: 'auth.password',
		resetLinkMessage: 'auth.resetLinkMessage',
	},
	avatarDropdown: {
		newPhoto: 'avatarDropdown.newPhoto',
		removePhoto: 'avatarDropdown.removePhoto',
	},
	changeEmailPage: {
		email: 'changeEmailPage.email',
		title: 'changeEmailPage.title',
	},
	changePasswordPage: {
		confirmNewPassword: 'changePasswordPage.confirmNewPassword',
		currentPassword: 'changePasswordPage.currentPassword',
		newPassword: 'changePasswordPage.newPassword',
		title: 'changePasswordPage.title',
	},
	commissionsPage: {
		history: {
			invoiceLinkText: 'commissionsPage.history.invoiceLinkText',
			tableColumns: {
				comment: 'commissionsPage.history.tableColumns.comment',
				date: 'commissionsPage.history.tableColumns.date',
				invoice: 'commissionsPage.history.tableColumns.invoice',
				status: 'commissionsPage.history.tableColumns.status',
			},
			title: 'commissionsPage.history.title',
		},
		personalDocumentsLinkText: 'commissionsPage.personalDocumentsLinkText',
		title: 'commissionsPage.title',
		verificationText: 'commissionsPage.verificationText',
		withdrawal: {
			actionText: 'commissions.withdrawal.actionText',
			actionDeniedText: 'commissions.withdrawal.actionDeniedText',
			invoiceFormatText: 'commissions.withdrawal.invoiceFormatText',
			submitBtnText: 'commissions.withdrawal.submitBtnText',
			title: 'commissions.withdrawal.title',
			uploadBtnText: 'commissions.withdrawal.uploadBtnText',
		},
	},
	common: {
		accept: 'common.accept',
		add: 'common.add',
		back: 'common.back',
		cancel: 'common.cancel',
		change: 'common.change',
		copy: 'common.copy',
		create: 'common.create',
		delete: 'common.delete',
		download: 'common.download',
		edit: 'common.edit',
		finish: 'common.finish',
		invite: 'common.invite',
		invoiceStatus: {
			accepted: 'common.invoiceStatus.accepted',
			analysis: 'common.invoiceStatus.analysis',
			rejected: 'common.invoiceStatus.rejected',
		},
		loading: 'common.loading',
		no: 'common.no',
		photos: {
			addPhoto: 'common.photos.addPhoto',
			photoFormatMsg: 'common.photos.photoFormatMsg',
			title: 'common.photos.title',
		},
		rankingTable: {
			group: 'common.rankingTable.group',
			individual: 'common.rankingTable.individual',
			leader: 'common.rankingTable.leader',
			rating: 'common.rankingTable.rating',
			score: 'common.rankingTable.score',
			user: 'common.rankingTable.user',
		},
		reject: 'common.reject',
		remove: 'common.remove',
		report: 'common.report',
		save: 'common.save',
		search: 'common.search',
		send: 'common.send',
		soon: 'common.soon',
		sort: 'common.sort',
		submit: 'common.submit',
		totalEnrollments: {
			salesCurrentYear: 'common.totalEnrollments.salesCurrentYear',
			salesSum: 'common.totalEnrollments.salesSum',
			title: 'common.totalEnrollments.title',
		},
		upload: 'common.upload',
		watchNow: 'common.watchNow',
		yes: 'common.yes',
	},
	confirmModal: {
		text: 'confirmModal.text',
	},
	coursesPage: {
		promo: 'coursesPage.promo',
		promoBtnText: 'coursesPage.promoBtnText',
		available: {
			title: 'coursesPage.available.title',
			buyLinkText: 'coursesPage.available.buyLinkText',
		},
	},
	groupFreeUsersPage: {
		table: {
			actions: 'groupFreeUsersPage.table.actions',
			rating: 'groupFreeUsersPage.table.rating',
			score: 'groupFreeUsersPage.table.score',
			userName: 'groupFreeUsersPage.table.userName',
		},
		tier: 'groupFreeUsersPage.tier',
		title: 'groupFreeUsersPage.title',
	},
	groupInvitationModal: {
		groupFreeUsers: 'groupInvitationModal.groupFreeUsers',
		invitationLink: 'groupInvitationModal.invitationLink',
		statusOfInvites: 'groupInvitationModal.statusOfInvites',
		title: 'groupInvitationModal.title',
	},
	groupInvitesPage: {
		status: {
			accepted:'groupInvitesPage.status.accepted',
			rejected:'groupInvitesPage.status.rejected',
			sent:'groupInvitesPage.status.sent',
		},
		statusPlaceholder: 'groupInvitesPage.statusPlaceholder',
		title: 'groupInvitesPage.title',
		table: {
			inviteStatus: 'groupInvitesPage.table.inviteStatus',
			rating: 'groupInvitesPage.table.rating',
			userName: 'groupInvitesPage.table.userName',
		},
	},
	groupMembersPage: {
		table: {
			actions: 'groupMembersPage.table.actions',
			created: 'groupMembersPage.table.created',
			memberName: 'groupMembersPage.table.memberName',
			rating: 'groupMembersPage.table.rating',
			score: 'groupMembersPage.table.score',
		},
		title: 'groupMembersPage.title',
	},
	groupPage: {
		createGroup: 'groupPage.createGroup',
		info: {
			rating: 'groupPage.info.rating',
		},
		inviteNewMembers: 'groupPage.inviteNewMembers',
		joinGroup: 'groupPage.joinGroup',
		leader: {
			title:'groupPage.leader.title',
		},
		leaveGroup: 'groupPage.leaveGroup',
		members: 'groupPage.members',
		membersAmount: 'groupPage.membersAmount',
		membersList: 'groupPage.membersList',
		notGroupMember: 'groupPage.notGroupMember',
		table: {
			memberName: 'groupPage.table.memberName',
			rating: 'groupPage.table.rating',
			score: 'groupPage.table.score',
		},
		title: 'groupPage.title',
	},
	homePage:{
		medias: 'homePage.medias',
		salesLink:{
			title: 'homePage.salesLink.title',
		},
		salesStatistics: {
			salesToNextLevel: 'homePage.salesStatistics.salesToNextLevel',
			title: 'homePage.salesStatistics.title',
			weekSales: 'homePage.salesStatistics.weekSales',
		},
		title: 'homePage.title',
		trainings: 'homePage.trainings',
		weeklyRanking: {
			actualWeek: 'homePage.weeklyRanking.actualWeek',
			lastWeek: 'homePage.weeklyRanking.lastWeek',
			title: 'homePage.weeklyRanking.title',
		},
	},
	leads: {
		addNewLead: 'leads.addNewLead',
		newLeadModal: {
			comment: 'leads.newLeadModal.comment',
			leadEmail: 'leads.newLeadModal.leadEmail',
			leadName: 'leads.newLeadModal.leadName',
			leadPhone: 'leads.newLeadModal.leadPhone',
			selectStatus: 'leads.newLeadModal.selectStatus',
			title: 'leads.newLeadModal.title',
		},
		status: {
			contacted: 'leads.status.contacted',
			inNegotiations: 'leads.status.inNegotiations',
			linkSent: 'leads.status.linkSent',
			lost: 'leads.status.lost',
			newLead: 'leads.status.newLead',
			postponed: 'leads.status.postponed',
			sold: 'leads.status.sold',
		},
		statusPlaceholder: 'leads.statusPlaceholder',
		table: {
			actions: 'leads.table.actions',
			commentPlaceholder: 'leads.table.commentPlaceholder',
			contacts: 'leads.table.contacts',
			created: 'leads.table.created',
			emailPlaceholder: 'leads.table.emailPlaceholder',
			name: 'leads.table.name',
			namePlaceholder: 'leads.table.namePlaceholder',
			phonePlaceholder: 'leads.table.phonePlaceholder',
			status: 'leads.table.status',
		},
		title: 'leads.title',
	},
	legalDataPage:{
		address:'legalDataPage.address',
		cnpj:'legalDataPage.cnpj',
		corporateName:'legalDataPage.corporateName',
		email:'legalDataPage.email',
		legalNature:'legalDataPage.legalNature',
		title:'legalDataPage.title',
		viewCnpj:'legalDataPage.viewCnpj',
	},
	legalDocumentsPage:{
		agentManualDescription: 'legalDocumentsPage.agentManualDescription',
		agentManualTitle: 'legalDocumentsPage.agentManualTitle',
		agentManualLink: 'legalDocumentsPage.agentManualLink',
		cookiesPolicyText: 'legalDocumentsPage.cookiesPolicyText',
		cookiesPolicyTitle: 'legalDocumentsPage.cookiesPolicyTitle',
		membershipConditionsText: 'legalDocumentsPage.membershipConditionsText',
		membershipConditionsTitle: 'legalDocumentsPage.membershipConditionsTitle',
		privacyTermText: 'legalDocumentsPage.privacyTermText',
		privacyTermTitle: 'legalDocumentsPage.privacyTermTitle',
		serviceContractText: 'legalDocumentsPage.serviceContractText',
		serviceContractTitle: 'legalDocumentsPage.serviceContractTitle',
		termsOfUseText: 'legalDocumentsPage.termsOfUseText',
		termsOfUseTitle: 'legalDocumentsPage.termsOfUseTitle',
		title: 'legalDocumentsPage.title',
	},
	mainMenu: {
		commissions: 'mainMenu.commissions',
		group: 'mainMenu.group',
		home: 'mainMenu.home',
		leadsBase: 'mainMenu.leadsBase',
		media: 'mainMenu.media',
		profile: 'mainMenu.profile',
		ranking: 'mainMenu.ranking',
		sales: 'mainMenu.sales',
		training: 'mainMenu.training',
	},
	mainSider: {
		userGreeting: 'mainSider.userGreeting',
	},
	mediasPage: {
		title: 'mediasPage.title',
	},
	message: {
		success: {
			changesSaved: 'message.success.changesSaved',
			complaintSent: 'message.success.complaintSent',
			downloadStarted: 'message.success.downloadStarted',
			documentUploaded: 'message.success.documentUploaded',
			forgotPasswordLinkSent: 'message.success.forgotPasswordLinkSent',
			imageAdded: 'message.success.imageAdded',
			imageDeleted: 'message.success.imageDeleted',
			invoiceSent: 'message.success.invoiceSent',
			leadAdded: 'message.success.leadAdded',
			leadDeleted: 'message.success.leadDeleted',
			linkCopied: 'message.success.linkCopied',
			passwordChanged: 'message.success.passwordChanged',
			phoneChanged: 'message.success.phoneChanged',
			registeredSuccessfully: 'message.success.registeredSuccessfully',
			withdrawalRequestSent: 'message.success.withdrawalRequestSent',
		},
	},
	newGroupModal: {
		addAvatar: 'newGroupModal.addAvatar',
		groupNamePlaceholder: 'newGroupModal.groupNamePlaceholder',
		title: 'newGroupModal.title',
	},
	notFoundPage: {
		text: 'notFoundPage.text',
		title: 'notFoundPage.title',
	},
	personalDataPage: {
		address: {
			city: 'personalDataPage.address.city',
			cityPlaceholder: 'personalDataPage.address.cityPlaceholder',
			complement: 'personalDataPage.address.complement',
			complementPlaceholder: 'personalDataPage.address.complementPlaceholder',
			country: 'personalDataPage.address.country',
			countryPlaceholder: 'personalDataPage.address.countryPlaceholder',
			neighborhood: 'personalDataPage.address.neighborhood',
			neighborhoodPlaceholder: 'personalDataPage.address.neighborhoodPlaceholder',
			number: 'personalDataPage.address.number',
			numberPlaceholder: 'personalDataPage.address.numberPlaceholder',
			state: 'personalDataPage.address.state',
			statePlaceholder: 'personalDataPage.address.statePlaceholder',
			street: 'personalDataPage.address.street',
			streetPlaceholder: 'personalDataPage.address.streetPlaceholder',
			title: 'personalDataPage.address.title',
			zip: 'personalDataPage.address.zip',
			zipPlaceholder: 'personalDataPage.address.zipPlaceholder',
		},
		paymentData: {
			account: 'personalDataPage.paymentData.account',
			accountPlaceholder: 'personalDataPage.paymentData.accountPlaceholder',
			addBankAccount: 'personalDataPage.paymentData.addBankAccount',
			addPixData: 'personalDataPage.paymentData.addPixData',
			agency: 'personalDataPage.paymentData.agency',
			agencyPlaceholder: 'personalDataPage.paymentData.agencyPlaceholder',
			bank: 'personalDataPage.paymentData.bank',
			bankPlaceholder: 'personalDataPage.paymentData.bankPlaceholder',
			cpf: 'personalDataPage.paymentData.cpf',
			cpfPlaceholder: 'personalDataPage.paymentData.cpfPlaceholder',
			noDataText: 'personalDataPage.paymentData.noDataText',
			pixAccount: 'personalDataPage.paymentData.pixAccount',
			pixAccountPlaceholder: 'personalDataPage.paymentData.pixAccountPlaceholder',
			pixAgency: 'personalDataPage.paymentData.pixAgency',
			pixAgencyPlaceholder: 'personalDataPage.paymentData.pixAgencyPlaceholder',
			pixBank: 'personalDataPage.paymentData.pixBank',
			pixBankPlaceholder: 'personalDataPage.paymentData.pixBankPlaceholder',
			pixCity: 'personalDataPage.paymentData.pixCity',
			pixCityPlaceholder: 'personalDataPage.paymentData.pixCityPlaceholder',
			pixCnpj: 'personalDataPage.paymentData.pixCnpj',
			pixCnpjPlaceholder: 'personalDataPage.paymentData.pixCnpjPlaceholder',
			pixCpf: 'personalDataPage.paymentData.pixCpf',
			pixCpfPlaceholder: 'personalDataPage.paymentData.pixCpfPlaceholder',
			pixEmail: 'personalDataPage.paymentData.pixEmail',
			pixEmailPlaceholder: 'personalDataPage.paymentData.pixEmailPlaceholder',
			pixKey: 'personalDataPage.paymentData.pixKey',
			pixKeyPlaceholder: 'personalDataPage.paymentData.pixKeyPlaceholder',
			pixPhone: 'personalDataPage.paymentData.pixPhone',
			pixPhonePlaceholder: 'personalDataPage.paymentData.pixPhonePlaceholder',
			pixState: 'personalDataPage.paymentData.pixState',
			pixStatePlaceholder: 'personalDataPage.paymentData.pixStatePlaceholder',
			removeBankAccount: 'personalDataPage.paymentData.removeBankAccount',
			removePixData: 'personalDataPage.paymentData.removePixData',
			title: 'personalDataPage.paymentData.title',
		},
		personalDocuments: {
			documentFormatMsg: 'personalDataPage.personalDocuments.documentFormatMsg',
			meiTabTitle: 'personalDataPage.personalDocuments.meiTabTitle',
			statusNotLoadedMei: 'personalDataPage.personalDocuments.statusNotLoadedMei',
			statusNotVerified: 'personalDataPage.personalDocuments.statusNotVerified',
			statusProcessing: 'personalDataPage.personalDocuments.statusProcessing',
			statusVerified: 'personalDataPage.personalDocuments.statusVerified',
			title: 'personalDataPage.personalDocuments.title',
			uploadAgainText: 'personalDataPage.personalDocuments.uploadAgainText',

		},
		personalInformation: {
			about: 'personalDataPage.personalInformation.about',
			aboutPlaceholder: 'personalDataPage.personalInformation.aboutPlaceholder',
			birthDate: 'personalDataPage.personalInformation.birthDate',
			birthDatePlaceholder: 'personalDataPage.personalInformation.birthDatePlaceholder',
			profession: 'personalDataPage.personalInformation.profession',
			professionPlaceholder: 'personalDataPage.personalInformation.professionPlaceholder',
			title: 'personalDataPage.personalInformation.title',
		},
		title: 'personalDataPage.title',
	},
	pix: {
		bankAccount: 'pix.bankAccount',
		cnpj: 'pix.cnpj',
		cpf: 'pix.cpf',
		email: 'pix.email',
		phone: 'pix.phone',
	},
	profileDropdown: {
		helpCenter: 'profileDropdown.helpCenter',
		whatsApp: 'profileDropdown.whatsApp',
	},
	profileInvitations:{
		inviteCoachEarn: 'profileInvitations.inviteCoachEarn',
		text: 'profileInvitations.text',
	},
	profileMenu: {
		bankData: 'profileMenu.bankData',
		invitations: 'profileMenu.invitations',
		legalData: 'profileMenu.legalData',
		legalDocuments: 'profileMenu.legalDocuments',
		personalData: 'profileMenu.personalData',
		securitySettings: 'profileMenu.securitySettings',
	},
	profilePage: {
		about: 'profilePage.about',
		title: 'profilePage.title',
	},
	profileStatus: {
		goalGetter_I: 'profileStatus.goalGetter_I',
		goalGetter_II: 'profileStatus.goalGetter_II',
		leader_I: 'profileStatus.leader_I',
		leader_II: 'profileStatus.leader_II',
		trueLeader_I: 'profileStatus.trueLeader_I',
		trueLeader_II: 'profileStatus.trueLeader_II',
		partner: 'profileStatus.partner',
	},
	rankingPage:{
		salesFrom: 'rankingPage.salesFrom',
		salesTo: 'rankingPage.salesTo',
		title: 'rankingPage.title',
	},
	registrationPage: {
		userForm: {
			city: 'registrationPage.userForm.city',
			cityPlaceholder: 'registrationPage.userForm.cityPlaceholder',
			country: 'registrationPage.userForm.country',
			countryPlaceholder: 'registrationPage.userForm.countryPlaceholder',
			email: 'registrationPage.userForm.email',
			emailPlaceholder: 'registrationPage.userForm.emailPlaceholder',
			fullName: 'registrationPage.userForm.fullName',
			fullNamePlaceholder: 'registrationPage.userForm.fullNamePlaceholder',
			phone: 'registrationPage.userForm.phone',
			phonePlaceholder: 'registrationPage.userForm.phonePlaceholder',
			requiredField: 'registrationPage.userForm.requiredField',
			state: 'registrationPage.userForm.state',
			statePlaceholder: 'registrationPage.userForm.statePlaceholder',
			submitBtnText: 'registrationPage.userForm.submitBtnText',
			title: 'registrationPage.userForm.title',
		},
	},
	reportModal: {
		checkAllThatApply: 'reportModal.checkAllThatApply',
		comment: 'reportModal.comment',
		commentPlaceholder: 'reportModal.commentPlaceholder',
		indencentAvatar: 'reportModal.indencentAvatar',
		indencentSubtext: 'reportModal.indencentSubtext',
		salaciousPhotos: 'reportModal.salaciousPhotos',
		salaciousSubtext: 'reportModal.salaciousSubtext',
		sensitive: 'reportModal.sensitive',
		sensitiveSubtext: 'reportModal.sensitiveSubtext',
		submitReport: 'reportModal.submitReport',
		title: 'reportModal.title',
	},
	salesPage: {
		table: {
			buyer: 'salesPage.table.buyer',
			commission: 'salesPage.table.commission',
			paymentType: 'salesPage.table.paymentType',
			price: 'salesPage.table.price',
			product: 'salesPage.table.product',
			purchaseDate: 'salesPage.table.purchaseDate',
			purchaseStatus: 'salesPage.table.purchaseStatus',
			refundPeriod: 'salesPage.table.refundPeriod',
			refundPeriodMsg: 'salesPage.table.refundPeriodMsg',
			transactionCode: 'salesPage.table.transactionCode',
		},
		title: 'salesPage.title',
	},
	securitySettingsPage: {
		changeEmail: 'securitySettingsPage.changeEmail',
		changePassword: 'securitySettingsPage.changePassword',
		phoneNumber: 'securitySettingsPage.phoneNumber',
		title: 'securitySettingsPage.title',
	},
	shareLink: {
		actionText: 'shareLink.actionText',
		copied: 'shareLink.copied',
	},
	trainingsPage: {
		title: 'trainingsPage.title',
	},
	validation: {
		invalidCnpj: 'validation.invalidCnpj',
		invalidCpf: 'validation.invalidCpf',
		invalidEmail: 'validation.invalidEmail',
		invalidPhone: 'validation.invalidPhone',
		numberBetween: 'validation.numberBetween',
		numberLessThan: 'validation.numberLessThan',
		numberMoreThan: 'validation.numberMoreThan',
		passwordsMustMatch: 'validation.passwordsMustMatch',
		passwordTooShort: 'validation.passwordTooShort',
		required: 'validation.required',
		valueFormat: 'validation.valueFormat',
	},
};
