import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { deleteCirclePurple } from 'assets/images';
import ButtonText from 'components/forms/button-text';
import UserRating from 'components/rating/user-rating';
import UserInfoColumn from 'components/tables/user-info-column';
import { getUserId } from 'store/auth';
import { removeGroupMemberAsync } from 'store/group-members';
import { IdType } from 'types/common';
import { GroupMembersListItem } from 'types/group-members';
import { RatingLevel } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import { formatDate } from 'utils/helpers/date-time';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './GroupMembersTable.scss';

type Props = {
	dataSource: GroupMembersListItem[];
};

const GroupMembersTable = ({ dataSource }: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const userId: IdType | null = useAppSelector(getUserId);

	const handleDelete = (userId: IdType) => {
		dispatch(removeGroupMemberAsync(userId));
	};

	const columns: ColumnsType<GroupMembersListItem> = [
		{
			title: () => (
				<FormattedMessage defaultMessage="Member name" id={Dictionary.groupMembersPage.table.memberName} />
			),
			dataIndex: 'nickname',
			key: 'nickname',
			render: (_: string, record: GroupMembersListItem) => (
				<UserInfoColumn active={record.id === userId} showRatingPosition={false} userInfo={record} />),
		},
		{
			title: () => <FormattedMessage defaultMessage="Created" id={Dictionary.groupMembersPage.table.created} />,
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: string) => (
				<div className="group-members-table__date">
					{formatDate(createdAt)}
				</div>
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Rating" id={Dictionary.groupMembersPage.table.rating} />,
			dataIndex: 'levelTitle',
			key: 'levelTitle',
			render: (levelTitle: RatingLevel, record: GroupMembersListItem) => (
				<UserRating
					intStars={record.intStars}
					levelTitle={levelTitle}
					ratingStarsMode={record.id === userId ? RatingStarsMode.ORANGE : RatingStarsMode.BLACK}
					starThirds={record.starThirds}
				/>
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Score" id={Dictionary.groupMembersPage.table.score} />,
			dataIndex: 'salesAmount',
			key: 'salesAmount',
			render: (salesAmount: number, record: GroupMembersListItem) => (
				<div className={record.id === userId ? 'active' : ''}>
					{salesAmount}
				</div>
			),
		},

		{
			title: () => <FormattedMessage defaultMessage="Actions" id={Dictionary.groupMembersPage.table.actions} />,
			dataIndex: 'id',
			key: 'id',
			render: (id: IdType) => id === userId ? null : (
				<ButtonText
					className="group-members-table__action-btn"
					defaultText="Remove"
					imgSrc={deleteCirclePurple}
					onClick={() => handleDelete(id)}
					textId={Dictionary.common.remove}
				/>
			),
		},
	];

	return (
		<Table
			className="group-members-table"
			columns={columns}
			dataSource={dataSource}
			rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default GroupMembersTable;
