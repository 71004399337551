import { useMemo } from 'react';
import {
	starEmpty, starEmptyWhite, starFullBlack,
	starFullPurple, starFullWhite, starOneThirdBlack,
	starOneThirdPurple, starOneThirdWhite, starTwoThirdsBlack,
	starTwoThirdsPurple, starTwoThirdsWhite,
} from 'assets/images';
import { AppTheme } from 'types/common';
import { RatingStarsMode, STARS_AMOUNT } from 'utils/constants/rating';
import './RatingStars.scss';

type Props = {
	intStars: number;
	ratingStarsMode?: RatingStarsMode;
	starThirds: number;
	theme?: AppTheme;
};

const RatingStars = ({
	intStars, ratingStarsMode = RatingStarsMode.BLACK, starThirds, theme = AppTheme.LIGHT,
}: Props): JSX.Element => {

	const starEmptyImg: string = useMemo(() => theme === AppTheme.LIGHT ? starEmpty : starEmptyWhite, [theme]);

	const starFullImg: string = useMemo(() => {
		if (ratingStarsMode === RatingStarsMode.ORANGE) return starFullPurple;
		if (ratingStarsMode === RatingStarsMode.WHITE) return starFullWhite;

		return starFullBlack;
	}, [ratingStarsMode]);

	const starThirdsImg: string = useMemo(() => {
		switch (starThirds) {
			case 1: {
				if (ratingStarsMode === RatingStarsMode.ORANGE) return starOneThirdPurple;
				if (ratingStarsMode === RatingStarsMode.WHITE) return starOneThirdWhite;

				return starOneThirdBlack;
			}

			case 2: {
				if (ratingStarsMode === RatingStarsMode.ORANGE) return starTwoThirdsPurple;
				if (ratingStarsMode === RatingStarsMode.WHITE) return starTwoThirdsWhite;

				return starTwoThirdsBlack;
			}

			default: return starEmptyImg;
		}
	}, [ratingStarsMode, starEmptyImg, starThirds]);

	return (
		<div className="rating-stars">
			{Array(intStars).fill(undefined).map((_, index) => (
				<img alt={`Star${index + 1}`} key={`Star${index + 1}`} src={starFullImg} />
			))}

			<img alt="Star_thirds" src={starThirdsImg} />

			{Array(STARS_AMOUNT - intStars - 1).fill(undefined).map((_, index) => (
				<img alt={`Star${index + 1}`} key={`Star${index + 1}`} src={starEmptyImg} />
			))}
		</div>
	);
};

export default RatingStars;
