import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { plusCirclePurple } from 'assets/images';
import ButtonText from 'components/forms/button-text';
import UserRating from 'components/rating/user-rating';
import UserInfoColumn from 'components/tables/user-info-column';
import { inviteToGroupAsync } from 'store/group-free-users';
import { getUserProfileData } from 'store/user-profile';
import { IdType } from 'types/common';
import { GroupFreeUsersListItem } from 'types/group-free-users';
import { RatingLevel } from 'types/rating';
import { UserProfile } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { RatingStarsMode } from 'utils/constants/rating';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import './GroupFreeUsersTable.scss';

type Props = {
	dataSource: GroupFreeUsersListItem[];
};

const GroupFreeUsersTable = ({ dataSource }: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const userProfile: UserProfile | null = useAppSelector(getUserProfileData);

	const handleInvite = (userId: IdType) => {
		dispatch(inviteToGroupAsync(userId));
	};

	const columns: ColumnsType<GroupFreeUsersListItem> = [
		{
			title: () => (
				<FormattedMessage defaultMessage="User name" id={Dictionary.groupFreeUsersPage.table.userName} />
			),
			dataIndex: 'nickname',
			key: 'nickname',
			render: (_: string, record: GroupFreeUsersListItem)=><UserInfoColumn userInfo={record} />,
		},
		{
			title: () => <FormattedMessage defaultMessage="Rating" id={Dictionary.groupFreeUsersPage.table.rating} />,
			dataIndex: 'levelTitle',
			key: 'levelTitle',
			render: (levelTitle: RatingLevel, record: GroupFreeUsersListItem) => (
				<UserRating
					intStars={record.intStars}
					levelTitle={levelTitle}
					ratingStarsMode={record.id === userProfile?.id ? RatingStarsMode.ORANGE : RatingStarsMode.BLACK}
					starThirds={record.starThirds}
				/>
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Score" id={Dictionary.groupFreeUsersPage.table.score} />,
			dataIndex: 'salesAmount',
			key: 'salesAmount',
		},

		{
			title: () => <FormattedMessage defaultMessage="Actions" id={Dictionary.groupFreeUsersPage.table.actions} />,
			dataIndex: 'id',
			key: 'id',
			render: (id: IdType) => id === userProfile?.id ? null : (
				<ButtonText
					className="group-free-users-table__action-btn"
					defaultText="Invite"
					imgSrc={plusCirclePurple}
					onClick={() => handleInvite(id)}
					textId={Dictionary.common.invite}
				/>
			),
		},
	];

	return (
		<Table
			className="group-free-users-table"
			columns={columns}
			dataSource={dataSource}
			rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default GroupFreeUsersTable;
