import MultipleSelect from 'components/multiple-select';
import { MultipleSelectItem } from 'components/multiple-select/MultipleSelect';
import { getGroupFreeUsersFilter, setGroupFreeUsersFilter } from 'store/group-free-users';
import { GroupFreeUsersFilter } from 'types/group-free-users';
import { RatingLevel } from 'types/rating';
import { Dictionary } from 'utils/constants/dictionary';
import './UserLevelSelector.scss';

const filterButtons: MultipleSelectItem<GroupFreeUsersFilter>[] = [
	{
		className: 'user-status-label_blue',
		defaultMessage: RatingLevel.GOAL_GETTER_I,
		filterKey: 'goalGetterI',
		labelId: Dictionary.profileStatus.goalGetter_I,
	},
	{
		className: 'user-status-label_blue',
		defaultMessage: RatingLevel.GOAL_GETTER_II,
		filterKey: 'goalGetterII',
		labelId: Dictionary.profileStatus.goalGetter_II,
	},
	{
		className: 'user-status-label_gold',
		defaultMessage: RatingLevel.LEADER_I,
		filterKey: 'leaderI',
		labelId: Dictionary.profileStatus.leader_I,
	},
	{
		className: 'user-status-label_gold',
		defaultMessage: RatingLevel.LEADER_II,
		filterKey: 'leaderII',
		labelId: Dictionary.profileStatus.leader_II,
	},
	{
		className: 'user-status-label_black',
		defaultMessage: RatingLevel.TRUE_LEADER_I,
		filterKey: 'trueLeaderI',
		labelId: Dictionary.profileStatus.trueLeader_I,
	},
	{
		className: 'user-status-label_black',
		defaultMessage: RatingLevel.TRUE_LEADER_II,
		filterKey: 'trueLeaderII',
		labelId: Dictionary.profileStatus.trueLeader_II,
	},
	{
		className: 'user-status-label_purple',
		defaultMessage: RatingLevel.PARTNER,
		filterKey: 'partner',
		labelId: Dictionary.profileStatus.partner,
	},
];

const UserLevelSelector = (): JSX.Element => (
	<MultipleSelect<GroupFreeUsersFilter>
		buttons={filterButtons}
		className="user-level-selector"
		defaultMessage="Tier"
		filterSelector={getGroupFreeUsersFilter}
		labelId={Dictionary.groupFreeUsersPage.tier}
		setFilterAction={setGroupFreeUsersFilter}
	/>
);

export default UserLevelSelector;
