import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import UserRating from 'components/rating/user-rating';
import UserInfoColumn from 'components/tables/user-info-column';
import { getUserId } from 'store/auth';
import { AppTheme, IdType } from 'types/common';
import { RatingLevel, UserRankingItem } from 'types/rating';
import { RatingStarsMode } from 'utils/constants/rating';
import { useAppSelector } from 'utils/hooks';
import { Dictionary } from 'utils/constants/dictionary';
import './UserRankingTable.scss';

type Props = {
	dataSource: UserRankingItem[];
	ratingStarsMode?: RatingStarsMode;
	theme?: AppTheme;
};

const UserRankingTable = ({
	dataSource, ratingStarsMode = RatingStarsMode.BLACK, theme = AppTheme.LIGHT,
}: Props): JSX.Element => {
	const userId: IdType | null = useAppSelector(getUserId);

	const columns = [
		{
			title: () => <FormattedMessage defaultMessage="User" id={Dictionary.common.rankingTable.user} />,
			dataIndex: 'nickname',
			key: 'nickname',
			render: (_: string, record: UserRankingItem)=> (
				<UserInfoColumn active={userId === record.id} showGroupAvatar userInfo={record} />
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Rating" id={Dictionary.common.rankingTable.rating} />,
			dataIndex: 'levelTitle',
			key: 'levelTitle',
			render: (levelTitle: RatingLevel, record: UserRankingItem) => (
				<UserRating
					intStars={record.intStars}
					levelTitle={levelTitle}
					ratingStarsMode={record.id === userId ? RatingStarsMode.ORANGE : ratingStarsMode}
					starThirds={record.starThirds}
					theme={theme}
				/>
			),
		},
		{
			title: () => <FormattedMessage defaultMessage="Score" id={Dictionary.common.rankingTable.score} />,
			dataIndex: 'salesAmount',
			key: 'salesAmount',
			render: (salesAmount: number, record: UserRankingItem) => (
				<div className={`user-ranking-table__count ${record.id === userId ? 'active' : ''}`}>
					{salesAmount}
				</div>
			),
		}];

	return (
		<Table
			className="user-ranking-table table"
			columns={columns} dataSource={dataSource} pagination={false} rowKey="id"
			scroll={{ y: '50vh' }}
		/>
	);
};

export default UserRankingTable;
