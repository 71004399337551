import { BaseEntity, IdType, Location } from './common';
import { UserInfo } from './user';

export enum RankingMode {
	INDIVIDUAL,
	TEAM,
}

export enum RatingLevel {
	GOAL_GETTER_I = 'GOAL_GETTER_I',
	GOAL_GETTER_II = 'GOAL_GETTER_II',
	LEADER_I = 'LEADER_I',
	LEADER_II = 'LEADER_II',
	TRUE_LEADER_I = 'TRUE_LEADER_I',
	TRUE_LEADER_II = 'TRUE_LEADER_II',
	PARTNER = 'PARTNER',
}

export enum RankingWeekQuery {
	CURRENT = 'current',
	LAST = 'last',
}

export interface Rating {
	intStars: number | null;
	starThirds: number | null;
	level: number;
	levelTitle: RatingLevel;
}

export interface Sales {
	salesAmount: number;
}

export interface RankingPosition {
	ratingNumber?: number;
}

export interface UserRankingItem extends BaseEntity, Location, RankingPosition, Rating, Sales {
	nickname: string | null;
	avatarImageId: IdType | null;
	groupId: IdType | null;
	groupAvatarImageId: IdType | null;
}

export interface GroupRankingItem extends BaseEntity, Rating, Sales, RankingPosition {
	groupName: string;
	groupAvatarImageId: IdType;
	creatorId: IdType;
	creatorName: string;
	creatorNickname: string;
}

export interface GroupMembersRankingItem extends UserInfo, Sales, RankingPosition {}

export type RankingFilter = {
	page?: string;
	search?: string;
	salesFrom?: string;
	salesTo?: string;
};
