import Button from 'components/forms/button';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { getUserId } from 'store/auth';
import { fetchUserProfileAsync, getUserProfileData } from 'store/user-profile';
import { IdType } from 'types/common';
import { RatingLevel } from 'types/rating';
import { UserProfile } from 'types/user-profile';
import { Dictionary } from 'utils/constants/dictionary';
import { useAppDispatch, useAppSelector, useWindow } from 'utils/hooks';
import GroupPage from '../group-page';
import NewGroupModal from '../new-group-modal';
import './GroupLayout.scss';

const groupAdminLevels: RatingLevel[] = [
	RatingLevel.LEADER_I,
	RatingLevel.LEADER_II,
	RatingLevel.TRUE_LEADER_I,
	RatingLevel.TRUE_LEADER_II,
	RatingLevel.PARTNER,
];

const GroupLayout = (): JSX.Element | null => {
	const dispatch = useAppDispatch();

	const userId: IdType | null = useAppSelector(getUserId);
	const userProfile: UserProfile | null = useAppSelector(getUserProfileData);

	const [newGroupModal, showNewGroupModal, hideNewGroupModal] = useWindow();

	useEffect(() => {
		if (userId) {
			dispatch(fetchUserProfileAsync(userId));
		}
	}, [dispatch, userId]);

	if (!userProfile || !userId) return null;

	const { groupId, levelTitle } = userProfile;

	if (groupId) return <GroupPage groupId={groupId} />;

	return (
		<div className="group-layout">
			<div>
				<FormattedMessage
					defaultMessage="You are not a member of any group"
					id={Dictionary.groupPage.notGroupMember}
				/>
			</div>

			{groupAdminLevels.includes(levelTitle) && (
				<div>
					<Button
						defaultText="Create your own group"
						onClick={showNewGroupModal}
						textId={Dictionary.groupPage.createGroup}
					/>
				</div>
			)}

			{newGroupModal && <NewGroupModal onClose={hideNewGroupModal} />}
		</div>
	);
};

export default GroupLayout;
